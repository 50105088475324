<template>
  <div>
    <accordion-main v-model="panel">
      <accordion-main-panel
        v-for="(item, index) in panels"
        :ref="`panel-${index}`"
        :key="index"
        :title="item.title"
        :subtitle="item.subtitle"
      >
        <template v-slot:header-prepend>
          <v-list-item-avatar class="section-avatar">
            <v-icon color="black">{{ item.icon }}</v-icon>
          </v-list-item-avatar>
        </template>
        <component
          v-if="item.key"
          :is="item.key"
          :accessStatus="accessStatus"
          @accessDenied="accessWarning()"
        />
      </accordion-main-panel>
      <v-overlay
        :value="accessStatus !== 'active'"
        absolute
        class="text-center"
        color="black"
        opacity="0.6"
      >
        <v-icon
          size="48"
          class="mb-4"
          color="white"
          >mdi-lock-outline</v-icon
        >
        <p
          v-if="accessStatus === 'expired'"
          class="text-white font-weight-bold"
        >
          Renew your account to update your wishes.
        </p>
        <p
          v-else
          class="text-white font-weight-bold"
        >
          Get access to save your wishes when you purchase your Will.
        </p>
        <v-btn
          v-bind="smallButton"
          @click="$router.push('/checkout')"
        >
          <template v-if="accessStatus === 'expired'">Renew now</template>
          <template v-else>Buy now</template>
        </v-btn>
      </v-overlay>
    </accordion-main>
    <div class="divider" />
    <page-funeral-download
      :accessStatus="accessStatus"
      @accessDenied="accessWarning()"
    />
    <dialog-small
      title="Wishes"
      :open.sync="openAccess"
    >
      <div class="pa-4">
        <p>
          The wishes feature of your account comes included when you purchase
          your Will.
        </p>
        <div>
          Get your Will now to start adding your wishes to your account.
        </div>
      </div>
      <template v-slot:buttons>
        <div class="divider" />
        <v-btn
          text
          tile
          x-large
          block
          download
          @click="$router.push('/checkout')"
          >Purchase Will</v-btn
        >
      </template>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AccordionMain from '../../components/wrapper/AccordionMain'
import AccordionMainPanel from '../../components/wrapper/AccordionMainPanel'
import DialogSmall from '../../components/wrapper/DialogSmall'
import PageFuneralDownload from './Download'
import FurtherInstruction from './sections/FurtherInstruction'
import Memorialisation from './sections/Memorialisation'
import FuneralService from './sections/Service'

export default {
  name: 'PageWishesSummary',
  components: {
    Memorialisation,
    FurtherInstruction,
    FuneralService,
    PageFuneralDownload,
    AccordionMain,
    AccordionMainPanel,
    DialogSmall,
  },
  data: () => ({
    panel: null,
    openAccess: false,
    panels: [
      {
        title: 'Memorialisation',
        subtitle: 'What would you like to happen after you pass away',
        key: 'memorialisation',
        icon: 'mdi-hand-heart-outline',
      },
      {
        title: 'Funeral Service',
        subtitle: 'What sort of service would you like',
        key: 'funeral-service',
        icon: 'mdi-candelabra-fire',
      },
      {
        title: 'Additional Funeral Wishes',
        subtitle: 'Enter further details',
        key: 'further-instruction',
        icon: 'mdi-message-text-outline',
      },
    ],
  }),
  methods: {
    accessWarning() {
      this.openAccess = true
    },
  },
  computed: {
    ...mapGetters('account', ['user_access']),
    accessStatus() {
      if (this.user_access.hasExpired) {
        return 'expired'
      }
      if (this.user_access.hasPurchasedWill) {
        return 'active'
      }

      return 'unactivated'
    },
  },
}
</script>
