<template>
  <v-list-item class="bg-grey-10 radius-4">
    <v-list-item-content>
      <slot />
    </v-list-item-content>
    <v-list-item-action v-if="$listeners.edit">
      <v-btn
        text
        small
        class="bg-white py-4 radius-4"
        @click="onClick"
        :class="{ 'disabled-btn': !disabled }"
        ><v-icon
          left
          color="primary"
          >mdi-pencil-outline</v-icon
        >Edit</v-btn
      >
    </v-list-item-action>
    <v-list-item-action v-if="$listeners.delete">
      <v-btn
        icon
        color="black"
        height="32"
        class="bg-white radius-4"
        @click="$emit('delete')"
        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'SectionItem',
  data: () => ({}),
  props: {
    disabled: {
      default: 'false',
    },
  },
  methods: {
    onClick() {
      if (this.disabled) this.$emit('edit')
      else this.$emit('clickDisabled')
    },
  },
}
</script>
