var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4"},[_c('p',[_vm._v(" Let your loved ones know of any preferences you have or plans you've arranged regarding what happens to your remains. ")]),(_vm.remains.type)?_c('div',[_c('h3',{staticClass:"py-2"},[_vm._v("Summary")]),_c('section-item',{staticClass:"mb-1",on:{"edit":function($event){return _vm.open('remains')}}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v(" What do you want done with your remains? ")]),_c('div',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.remains.label))])]),_vm._l((_vm.questions.filter((x) => !x.related)),function(q){return _c('section-item',{key:q.key,staticClass:"mb-1",on:{"edit":function($event){return _vm.open(q.key)}}},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v(" "+_vm._s(q.question)+" ")]),(_vm.vault_wishes_memorialisation.isComplete)?_c('div',[_c('answer-component',{attrs:{"q":q,"vault_wishes":_vm.vault_wishes_memorialisation.value}},[_vm._l((_vm.questions.filter((x) => x.related === q.key)),function(nested){return [(
                !nested.showIf ||
                nested.showIf(
                  _vm.vault_wishes_memorialisation.value[nested.related]
                )
              )?_c('answer-component',{key:nested.key,attrs:{"q":nested,"vault_wishes":_vm.vault_wishes_memorialisation.value}},[_vm._l((_vm.questions.filter(
                  (x) => x.related === nested.key
                )),function(nested2){return [(
                    !nested2.showIf ||
                    nested2.showIf(
                      _vm.vault_wishes_memorialisation.value[nested2.related]
                    )
                  )?_c('answer-component',{key:nested2.key,attrs:{"q":nested2,"vault_wishes":_vm.vault_wishes_memorialisation.value}}):_vm._e()]})],2):_vm._e()]})],2)],1):_c('div',[_c('div',{staticClass:"text-no-result text-left pa-0"},[_vm._v("No answer given")])])])})],2):_c('div',{staticClass:"text-center pb-4"},[_vm._m(0),_c('div',{staticClass:"mt-4"},[_c('v-btn',_vm._b({on:{"click":function($event){return _vm.open('remains')}}},'v-btn',_vm.smallButton,false),[_vm._v("Get Started")])],1)]),_c('dialog-full',{attrs:{"open":_vm.dialogOpen,"title":"Memorialisation","persistent":"","path":"/memorialisation","base-path":"/wishes"},on:{"update:open":function($event){_vm.dialogOpen=$event}}},[(_vm.openForm)?_c(`memorial-${_vm.openForm}-form`,{tag:"component",attrs:{"qkey":_vm.questionKey,"qgroup":"memorialisation"},on:{"close":_vm.close}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4 text-center"},[_c('h3',{staticClass:"mb-4"},[_vm._v(" Let your loved ones know your wishes for your remains ")]),_c('div',{staticClass:"opacity-70"},[_vm._v(" Answer a few questions about what you would like to happen after you are gone. ")])])
}]

export { render, staticRenderFns }