<template>
  <v-dialog
    v-model="isOpen"
    :max-width="500"
    persistent
  >
    <v-card>
      <v-list-item
        dense
        class="bg-sand"
      >
        <v-list-item-content>
          <h3 class="dialog-small-title">{{ title }}</h3>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="isOpen = false"
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <slot />
      <slot name="buttons" />
      <div class="divider" />
      <v-btn
        text
        tile
        block
        class="bg-grey-10"
        @click="
          () => {
            isOpen = false
            $emit('close')
          }
        "
        >Close</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogSmall',
  props: {
    title: {
      default: 'Notice',
    },
    open: {
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    isOpen: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('update:open', val)
      },
    },
  },
}
</script>

<style lang="scss">
.dialog-small-title {
  font-family: CooperBT !important;
}
</style>
