<template>
  <div>
    <step-progress
      :current="window"
      :total="questionCount"
      class="mb-6"
    />
    <div class="divider mb-6 opacity-80"></div>
    <v-window
      touchless
      v-model="window"
    >
      <v-window-item
        v-for="(question, index) in questions"
        :key="question.key"
        :value="index + 1"
      >
        <services-wish-form
          :submitLabel="questionCount === window ? 'Save' : 'Next'"
          closeLabel="Back"
          onSaveBehaviour="next"
          :qkey="question.key"
          :qgroup="qgroup"
          @close="
            () => {
              window === 1 ? close() : prevStep()
            }
          "
          @next="
            () => {
              questionCount === window ? close() : nextStep()
            }
          "
        />
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import StepProgress from '../../../components/ui/StepProgress'
import ServicesWishForm from './Wishes'
export default {
  name: 'FuneralGetStarted',
  components: { StepProgress, ServicesWishForm },
  props: {
    qgroup: {
      default: '',
      required: true,
    },
  },
  data: () => ({
    window: 1,
  }),
  methods: {
    nextStep() {
      this.window++
    },
    prevStep() {
      this.window--
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
  },
  computed: {
    questions() {
      return this.$store.getters['account/list_group'](this.qgroup).filter(
        (x) => !x.related && (!x.remains || x.remains.includes(this.remains))
      )
    },
    questionCount() {
      return this.questions.length
    },
  },
  mounted() {},
  watch: {
    window() {
      document.querySelectorAll('.dialog-full-container').forEach((el) => {
        el.scrollTo(0, 0)
      })
    },
  },
}
</script>
