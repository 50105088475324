<template>
  <div>
    <div class="inner">
      <div class="d-flex align-center">
        <h1 class="font-cooper">Funeral Wishes</h1>
        <v-spacer />
      </div>
    </div>
    <div class="pa-4">
      <p>
        Letting your family and friends know your funeral wishes will help them
        arrange a farewell that’s just right for you.
      </p>
      <faqs :faqs="['whatWishes', 'whereStoreWishes']" />
    </div>
    <div class="divider" />

    <page-wishes-summary />
  </div>
</template>

<script>
import Faqs from '../components/ui/Faqs'
import PageWishesSummary from './wishes/Summary'
export default {
  name: 'PageWishes',
  components: { Faqs, PageWishesSummary },
  data: () => ({
    tab: 0,
    tabs: [
      {
        key: 'wishes',
        label: 'Wishes',
      },
      {
        key: 'download',
        label: 'Download',
      },
    ],
  }),
}
</script>

<style lang="scss"></style>
