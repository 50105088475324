var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.questions.filter(
      (x) => !x.remains || x.remains.includes(_vm.remains)
    )),function(question,i){return _c('div',{key:i,staticClass:"mb-6"},[(
        !question.showIf || question.showIf(_vm.localFormatted[question.related])
      )?[_c(i === 0 ? 'h2' : 'h3',{tag:"component",staticClass:"mb-4 line-height-md"},[_vm._v(_vm._s(question.question))]),(question.description)?_c('p',[_vm._v(_vm._s(question.description))]):_vm._e(),(!question.options)?_c('v-textarea',{attrs:{"outlined":""},model:{value:(_vm.local[question.key]),callback:function ($$v) {_vm.$set(_vm.local, question.key, $$v)},expression:"local[question.key]"}}):_vm._e(),(question.options)?_c('div',[_vm._l((question.options.map((x) =>
            typeof x === 'object' ? x : { key: x, label: x }
          )),function(item){return _c('item-select',{key:item.key,staticClass:"mb-2",attrs:{"value":item.key === _vm.localFormatted[question.key],"title":item.label},on:{"select":function($event){_vm.local[question.key] = item.key}}})}),_c('v-input',{attrs:{"error-messages":null}})],2):_vm._e()]:_vm._e()],2)}),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',_vm._b({on:{"click":_vm.close}},'v-btn',_vm.backButton,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(_vm._s(_vm.closeLabel))],1),_c('v-spacer'),_c('v-btn',_vm._b({attrs:{"depressed":""},on:{"click":_vm.submit}},'v-btn',_vm.buttonType,false),[_vm._v("Save")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }