<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="loading-icon"
  >
    <path d="M21 12a9 9 0 1 1-6.219-8.56"></path>
  </svg>
</template>

<script>
export default {
  name: 'LoadingIcon',
  data: () => ({}),
}
</script>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon {
  animation: spin 1s linear infinite;
}
</style>
