<template>
  <div class="pa-4 text-center">
    <v-btn
      v-bind="largeButton"
      :color="accessStatus === 'unactivated' ? 'bg-grey-20' : 'primary'"
      @click="generateDocument()"
      :loading="generating"
      >Download</v-btn
    >
    <download-doc
      title="Download Wishes"
      :token.sync="downloadToken"
    >
      <div class="mt-4">
        <div class="text-label">Important</div>
        <div>
          Although you might like to store a printed copy of your Funeral Wishes
          alongside your Will, it’s important that you
          <strong>DO NOT</strong> attach them.
        </div>
      </div>
    </download-doc>
    <v-overlay v-if="generating">
      <v-card>
        <v-card-text>
          <p>Generating document</p>
          <p>This can take up to 10 seconds.</p>
        </v-card-text>
        <v-progress-linear indeterminate></v-progress-linear>
      </v-card>
    </v-overlay>

    <dialog-small
      title="Download"
      :open.sync="openAlertDialog"
    >
      <div class="pa-4">
        <div>At least, you must fill one question of any section</div>
      </div>
    </dialog-small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { functions } from '../../../plugins/firebase'
import DownloadDoc from '../../components/site/DownloadDoc'
import DialogSmall from '../../components/wrapper/DialogSmall'

export default {
  name: 'PageFuneralDownload',
  components: {
    DownloadDoc,
    DialogSmall,
  },
  props: {
    accessStatus: {
      default: 'unactivated',
    },
  },
  data: () => ({
    downloadToken: null,
    generating: false,
    openAlertDialog: false,
  }),
  computed: {
    ...mapGetters('account', [
      'will_funeral_wishes',
      'vault_wishes_service',
      'vault_wishes_memorialisation',
    ]),
  },
  methods: {
    async generateDocument() {
      if (this.accessStatus === 'unactivated') {
        this.$emit('accessDenied')
        return
      }

      if (
        !this.vault_wishes_service.isComplete &&
        !this.vault_wishes_memorialisation.isComplete &&
        !this.will_funeral_wishes.value.remains &&
        !this.will_funeral_wishes.value.message
      ) {
        this.openAlertDialog = true
        return
      }

      this.generating = true
      let resp = await functions.httpsCallable('documentsGenerateWishesAu')({
        uid: this.$store.getters['account/auth_user'].uid,
      })
      if (resp.data?.token) {
        this.downloadToken = resp.data.token
      }
      this.generating = false
    },
  },
}
</script>

<style lang="scss"></style>
