<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h3 class="mb-4">Message</h3>
        <v-textarea
          outlined
          v-model="local.message"
          :error-messages="
            showErrors.message && errorMessages.message
              ? errorMessages.message
              : null
          "
        />
      </div>
      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'FurtherInstructionForm',
  props: {
    closeLabel: {
      default: 'Close',
    },
    submitLabel: {
      default: 'Save',
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      message: true,
    },
    processing: false,
  }),
  mounted() {
    this.local = cloneDeep(this.will_funeral_wishes.value)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.close()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch(
        'account/save_will_funeral_wishes',
        this.localFormatted
      )
    },
    close() {
      this.$emit('close')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    ...mapGetters('account', ['will_funeral_wishes']),
    options() {
      return this.$store.getters['account/list_group']('remains')
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        message: null,
      }

      if (this.localFormatted.message.length > 100000) {
        msgs.message = 'Maximum length of 100,000 characters'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_funeral_wishes.value)
    },
    localFormatted() {
      return {
        message: this.local.message.trim(),
      }
    },
  },
}
</script>

<style lang="scss"></style>
