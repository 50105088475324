<template>
  <div>
    <div class="d-flex align-center py-2">
      <h3>
        Do you have any further wishes or other details you would like to share?
      </h3>
      <v-spacer />
      <v-btn
        text
        class="bg-grey-20 radius-4"
        @click="edit()"
        ><v-icon
          color="primary"
          left
          >mdi-pencil</v-icon
        >Edit</v-btn
      >
    </div>
    <transition
      name="component-fade"
      mode="out-in"
    >
      <v-card
        flat
        color="bg-grey-10"
        class="pa-4"
      >
        <template v-if="will_funeral_wishes.value.message">
          {{ will_funeral_wishes.value.message }}
        </template>
        <div
          class="text-no-result"
          v-else
        >
          No answer provided
        </div>
      </v-card>
    </transition>

    <dialog-full
      :open.sync="dialogOpen"
      title="Further Instruction"
      persistent
      path="/instructions"
      base-path="/wishes"
    >
      <component
        v-if="openForm"
        :is="`further-instruction-${openForm}`"
        @close="close"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DialogFull from '../../../components/wrapper/DialogFull'
import FurtherInstructionForm from '../forms/FurtherInstruction'
import mixins from './mixins'

export default {
  name: 'WillWishes',
  mixins: [mixins],
  components: { DialogFull, FurtherInstructionForm },
  data: () => ({
    openForm: null,
  }),
  methods: {
    close() {
      this.openForm = null
    },
    edit() {
      if (this.accessStatus !== 'active') {
        this.$emit('accessDenied')
        return
      }

      this.openForm = 'form'
    },
  },
  computed: {
    ...mapGetters('account', ['will_funeral_wishes']),
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
  },
}
</script>

<style lang="scss"></style>
