<template>
  <v-dialog
    fullscreen
    persistent
    transition="dialog-bottom-transition"
    :value="isOpen"
    v-bind="$attrs"
  >
    <v-card
      flat
      tile
      class="dialog-full-card"
    >
      <div class="dialog-full-header bg-sand">
        <div class="inner">
          <div>
            <div
              v-if="headline"
              class="text-sm font-weight-bold opacity-70"
            >
              {{ headline }}
            </div>
            <div class="dialog-full-header-title">{{ title }}</div>
          </div>
          <v-spacer />
          <v-btn
            @click="isOpen = false"
            text
            >Exit</v-btn
          >
        </div>
      </div>
      <div
        class="dialog-full-container"
        ref="container"
        tabindex="0"
      >
        <div ref="top"></div>
        <div
          class="inner"
          id="slotContainer"
        >
          <slot />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogFull',
  props: {
    open: {
      default: false,
    },
    title: {
      default: '',
    },
    headline: {
      default: '',
    },
    path: {},
    basePath: {
      default: '',
    },
  },
  data: () => ({}),
  watch: {
    open(val) {
      if (val) {
        document.documentElement.classList.add('dialog-open')
        setTimeout(() => {
          this.focusContainer()
        }, 500)
      } else {
        document.documentElement.classList.remove('dialog-open')
      }
      this.setHistory()
    },
  },
  methods: {
    focusContainer() {
      this.$refs.container.focus()
    },
    scrollTop(pos = 0) {
      let top = this.$refs.top.getBoundingClientRect().top
      this.$refs.container.scrollTop = pos - top
    },
    scrollTop2() {
      this.$refs.container.scrollTop = 0
    },
    setHistory() {
      if (!this.path) return window.location.pathname

      const path = this.isOpen
        ? `${this.basePath}${this.path}`
        : this.basePath || '/'

      history.pushState({ urlPath: path }, '', path)
    },
  },
  computed: {
    isOpen: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('update:open', val)
      },
    },
  },
}
</script>

<style lang="scss">
html.dialog-open {
  overflow-y: hidden !important;
}
.dialog-full-card {
  position: relative;
}
.dialog-full-header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  .inner {
    max-width: 648px;
    margin: 0 auto;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .dialog-full-header-title {
    font-family: CooperBT;
    font-size: 1.5rem;
    font-weight: bold;
    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
    @media (max-width: 400px) {
      font-size: 1.1rem;
    }
  }
}
.dialog-full-container {
  scroll-behavior: smooth;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  > .inner {
    margin: 0 auto;
    padding: 24px 16px 200px;
    max-width: 600px;
  }
  &:focus {
    outline: none;
  }
}
</style>
