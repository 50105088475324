<template>
  <div>
    <div class="text-sm font-weight-medium mb-2">
      Step {{ current }} <template v-if="!hideTotal">of {{ total }}</template>
    </div>
    <v-progress-linear
      rounded
      :height="8"
      :value="progress"
      color="primary"
      background-color="#e5e5e5"
      background-opacity="100"
    />
  </div>
</template>

<script>
export default {
  name: 'StepProgress',
  props: {
    current: {
      default: 1,
    },
    total: {
      default: 1,
    },
    hideTotal: {
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    progress() {
      return (this.current * 100) / this.total
    },
  },
}
</script>

<style lang="scss"></style>
