<template>
  <button
    @click="$emit('click')"
    class="save-btn"
    :class="{ disabled: disabled }"
    :disabled="disabled || loading"
  >
    <div class="text"><slot /></div>
    <div
      v-if="icon"
      class="icon bg-primary"
    >
      <v-icon>{{ icon }}</v-icon>
    </div>
    <v-fade-transition>
      <v-overlay
        v-if="loading"
        absolute
        color="primary"
        opacity="1"
      >
        <v-progress-circular
          :width="3"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-fade-transition>
  </button>
</template>

<script>
export default {
  name: 'SaveBtn',
  props: {
    loading: {
      default: false,
    },
    disabled: {
      default: false,
    },
    icon: {
      default: 'mdi-arrow-right',
    },
  },
  data: () => ({}),
}
</script>

<style lang="scss">
.save-btn {
  height: 56px;
  border-radius: 4px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  overflow: hidden;
  background: linear-gradient(to right, var(--primary) 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  position: relative;
  &:hover:not(:disabled) {
    background-position: left bottom;
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
  .text {
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
    min-width: 120px;
  }
  .icon {
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      color: #000000;
    }
  }
}
</style>
