<template>
  <div>
    <div v-if="vault_wishes_service.isComplete">
      <p>Share details about what you would like at your funeral.</p>
      <h3 class="py-2">Summary</h3>
      <section-item
        @edit="open(q.key)"
        class="mb-1"
        v-for="q in questions.filter((x) => !x.related)"
        :key="q.key"
      >
        <div class="font-weight-bold mb-2">
          {{ q.question }}
        </div>
        <div v-if="vault_wishes_service.isComplete">
          <answer-component
            :q="q"
            :vault_wishes="vault_wishes_service.value"
          >
            <answer-component
              v-for="nested in questions.filter((x) => x.related === q.key)"
              :key="nested.key"
              :q="nested"
              :vault_wishes="vault_wishes_service.value"
            />
          </answer-component>
        </div>
      </section-item>
    </div>
    <div
      v-else
      class="text-center pb-4"
    >
      <div class="py-4 text-center">
        <h3 class="mb-4 line-height-md">
          Save details about your preferred funeral arrangements so your loved
          ones can remember your life the way you’d like them to.
        </h3>
        <div class="opacity-70">
          Answer a few questions about what you would like to happen after you
          are gone.
        </div>
      </div>
      <div class="mt-4">
        <v-btn
          v-bind="smallButton"
          @click="open('get-started')"
          >Get Started</v-btn
        >
      </div>
    </div>
    <dialog-full
      :open.sync="dialogOpen"
      title="Funeral Service"
      path="/funeral"
      base-path="/wishes"
    >
      <component
        v-if="openForm"
        :is="`funeral-${openForm}-form`"
        :qkey="questionKey"
        qgroup="services"
        @close="close"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import AnswerComponent from '../blocks/WishAnswer'
import FuneralGetStartedForm from '../forms/FuneralGetStarted'
import FuneralWishesForm from '../forms/Wishes'
import mixins from './mixins'

export default {
  name: 'FuneralService',
  mixins: [mixins],
  components: {
    DialogFull,
    FuneralWishesForm,
    SectionItem,
    FuneralGetStartedForm,
    AnswerComponent,
  },
  data: () => ({
    openForm: null,
    questionKey: null,
  }),
  methods: {
    close() {
      this.openForm = null
    },
    open(key) {
      if (this.accessStatus !== 'active') {
        this.$emit('accessDenied')
        return
      }

      if (key === 'get-started') {
        this.openForm = key
        return
      }

      this.questionKey = key
      this.openForm = 'wishes'
    },
  },
  computed: {
    ...mapGetters('account', ['vault_wishes_service']),
    questions() {
      return this.$store.getters['account/list_group']('services')
    },
    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
  },
}
</script>

<style lang="scss"></style>
