<template>
  <div>
    <div
      class="mb-6"
      v-for="(question, i) in questions.filter(
        (x) => !x.remains || x.remains.includes(remains)
      )"
      :key="i"
    >
      <template
        v-if="
          !question.showIf || question.showIf(localFormatted[question.related])
        "
      >
        <component
          :is="i === 0 ? 'h2' : 'h3'"
          class="mb-4 line-height-md"
          >{{ question.question }}</component
        >
        <p v-if="question.description">{{ question.description }}</p>
        <v-textarea
          outlined
          v-model="local[question.key]"
          v-if="!question.options"
        />
        <div v-if="question.options">
          <item-select
            v-for="item in question.options.map((x) =>
              typeof x === 'object' ? x : { key: x, label: x }
            )"
            :key="item.key"
            :value="item.key === localFormatted[question.key]"
            :title="item.label"
            @select="local[question.key] = item.key"
            class="mb-2"
          />
          <v-input :error-messages="null" />
        </div>
      </template>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
        ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >Save</v-btn
      >
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'

import ItemSelect from '../../../components/ui/ItemSelect'
export default {
  name: 'MemorialWishesForm',
  components: { ItemSelect },
  props: {
    closeLabel: {
      default: 'close',
    },
    submitLabel: {
      default: 'Save',
    },
    onSaveBehaviour: {
      default: 'close', // close | next
    },
    qkey: {
      default: '',
    },
    qgroup: {
      default: '',
    },
  },
  data: () => ({
    local: {},
    showErrors: {},
    processing: false,
  }),
  mounted() {
    this.local = cloneDeep(this.wishesThePicked)
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          if (this.onSaveBehaviour === 'close') {
            this.close()
          }
          if (this.onSaveBehaviour === 'next') {
            this.$emit('next')
          }
        }, 500)
        return
      }

      this.processing = false
      this.showErrorsSwitch()
    },
    async save() {
      await this.$store.dispatch('account/save_vault', {
        id: 'wishes',
        data: { [this.qgroup]: this.localFormatted },
      })
    },
    close() {
      this.$emit('close')
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    remains() {
      return this.$store.getters['account/will_funeral_wishes'].value.remains
    },
    vaultWishes() {
      return this.$store.getters['account/vault_wishes'](this.qgroup).value
    },
    questions() {
      const allQuestions = this.$store.getters['account/list_group'](
        this.qgroup
      )

      const q = allQuestions.find((x) => x.key === this.qkey)
      let questions = [q]
      const getRelated = (_q) => {
        const subQ = allQuestions.filter((x) => x.related === _q.key)
        questions = [...questions, ...subQ]
        subQ.forEach((item) => {
          getRelated(item)
        })
      }
      getRelated(q)

      return questions
    },
    wishesThePicked() {
      return this.questions.reduce(
        (obj, q) => ({
          ...obj,
          [q.key]: this.vaultWishes[q.key],
        }),
        {}
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.wishesThePicked)
    },
    localFormatted() {
      return this.questions.reduce(
        (obj, q) => ({
          ...obj,
          [q.key]:
            q.showIf && !q.showIf(this.local[q.related])
              ? undefined
              : this.local[q.key],
        }),
        {}
      )
    },
  },
}
</script>

<style lang="scss"></style>
