<template>
  <div class="py-4">
    <p>
      Let your loved ones know of any preferences you have or plans you've
      arranged regarding what happens to your remains.
    </p>
    <div v-if="remains.type">
      <h3 class="py-2">Summary</h3>
      <section-item
        @edit="open('remains')"
        class="mb-1"
      >
        <div class="font-weight-bold mb-2">
          What do you want done with your remains?
        </div>
        <div class="mb-2">{{ remains.label }}</div>
      </section-item>
      <section-item
        @edit="open(q.key)"
        class="mb-1"
        v-for="q in questions.filter((x) => !x.related)"
        :key="q.key"
      >
        <div class="font-weight-bold mb-2">
          {{ q.question }}
        </div>
        <div v-if="vault_wishes_memorialisation.isComplete">
          <answer-component
            :q="q"
            :vault_wishes="vault_wishes_memorialisation.value"
          >
            <template
              v-for="nested in questions.filter((x) => x.related === q.key)"
            >
              <answer-component
                v-if="
                  !nested.showIf ||
                  nested.showIf(
                    vault_wishes_memorialisation.value[nested.related]
                  )
                "
                :key="nested.key"
                :q="nested"
                :vault_wishes="vault_wishes_memorialisation.value"
              >
                <template
                  v-for="nested2 in questions.filter(
                    (x) => x.related === nested.key
                  )"
                >
                  <answer-component
                    v-if="
                      !nested2.showIf ||
                      nested2.showIf(
                        vault_wishes_memorialisation.value[nested2.related]
                      )
                    "
                    :key="nested2.key"
                    :q="nested2"
                    :vault_wishes="vault_wishes_memorialisation.value"
                  />
                </template>
              </answer-component>
            </template>
          </answer-component>
        </div>
        <div v-else>
          <div class="text-no-result text-left pa-0">No answer given</div>
        </div>
      </section-item>
    </div>
    <div
      v-else
      class="text-center pb-4"
    >
      <div class="py-4 text-center">
        <h3 class="mb-4">
          Let your loved ones know your wishes for your remains
        </h3>
        <div class="opacity-70">
          Answer a few questions about what you would like to happen after you
          are gone.
        </div>
      </div>
      <div class="mt-4">
        <v-btn
          v-bind="smallButton"
          @click="open('remains')"
          >Get Started</v-btn
        >
      </div>
    </div>

    <dialog-full
      :open.sync="dialogOpen"
      title="Memorialisation"
      persistent
      path="/memorialisation"
      base-path="/wishes"
    >
      <component
        v-if="openForm"
        :is="`memorial-${openForm}-form`"
        @close="close"
        :qkey="questionKey"
        qgroup="memorialisation"
      />
    </dialog-full>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BtnGetStarted from '../../../components/ui/SaveBtn'
import DialogFull from '../../../components/wrapper/DialogFull'
import SectionItem from '../../../components/wrapper/SectionItem'
import AnswerComponent from '../blocks/WishAnswer'
import MemorialGetStartedForm from '../forms/MemorialGetStarted'
import MemorialWishesForm from '../forms/Wishes'
import mixins from './mixins'

export default {
  name: 'Memorialisation',
  mixins: [mixins],
  components: {
    DialogFull,
    BtnGetStarted,
    AnswerComponent,
    MemorialWishesForm,
    MemorialGetStartedForm,
    SectionItem,
  },
  data: () => ({
    openForm: null,
    questionKey: null,
  }),
  methods: {
    close() {
      this.openForm = null
    },
    open(key) {
      if (this.accessStatus !== 'active') {
        this.$emit('accessDenied')
        return
      }

      this.questionKey = key
      this.openForm = key === 'remains' ? 'get-started' : 'wishes'
    },
  },
  computed: {
    ...mapGetters('account', ['vault_wishes_memorialisation']),
    questions() {
      return this.$store.getters['account/list_group'](
        'memorialisation'
      ).filter((x) => !x.remains || x.remains.includes(this.remains.type))
    },
    remains() {
      return {
        type: this.$store.getters['account/will_funeral_wishes'].value.remains,
        label:
          this.$store.getters['account/will_funeral_wishes'].selectedOption
            ?.label,
      }
    },

    dialogOpen: {
      get() {
        return Boolean(this.openForm)
      },
      set(val) {
        if (!val) {
          this.close()
        }
      },
    },
  },
}
</script>

<style lang="scss"></style>
