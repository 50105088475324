<template>
  <div>
    <step-progress
      :current="window"
      :total="questionCount"
      :hideTotal="window === 1"
      class="mb-6"
    />
    <div class="divider mb-6 opacity-80"></div>
    <v-window
      touchless
      v-model="window"
    >
      <v-window-item :value="1">
        <memorial-remains-form
          submitLabel="Next"
          onSaveBehaviour="next"
          @close="close"
          @next="
            () => {
              remains === 'family' ? close() : nextStep()
            }
          "
        />
      </v-window-item>
      <v-window-item
        v-for="(question, index) in questions"
        :key="question.key"
        :value="index + 2"
      >
        <memorial-wish-form
          :submitLabel="questionCount === window ? 'Save' : 'Next'"
          closeLabel="Back"
          onSaveBehaviour="next"
          :qkey="question.key"
          :qgroup="qgroup"
          @close="
            () => {
              window === 1 ? close() : prevStep()
            }
          "
          @next="
            () => {
              questionCount === window ? close() : nextStep()
            }
          "
        />
      </v-window-item>
    </v-window>
  </div>
</template>
<script>
import StepProgress from '../../../components/ui/StepProgress'
import MemorialRemainsForm from './Remains'
import MemorialWishForm from './Wishes'
export default {
  name: 'MemorialWishesGetStarted',
  components: { StepProgress, MemorialRemainsForm, MemorialWishForm },
  props: {
    qgroup: {
      default: '',
      required: true,
    },
  },
  data: () => ({
    window: 1,
  }),
  methods: {
    nextStep() {
      this.window++
    },
    prevStep() {
      this.window--
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    },
  },
  computed: {
    remains() {
      return this.$store.getters['account/will_funeral_wishes'].value.remains
    },
    questions() {
      return this.$store.getters['account/list_group'](this.qgroup).filter(
        (x) => !x.related && (!x.remains || x.remains.includes(this.remains))
      )
    },
    questionCount() {
      return this.window === 1 ? 5 : this.questions.length + 1
    },
  },
  mounted() {},
  watch: {
    window() {
      document.querySelectorAll('.dialog-full-container').forEach((el) => {
        el.scrollTo(0, 0)
      })
    },
  },
}
</script>
