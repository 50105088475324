<template>
  <v-expansion-panel
    class="accordion-main-panel"
    :class="{ disabled: disabled }"
  >
    <v-expansion-panel-header
      :class="{ 'py-1': hasHeadlineSlot, 'py-2': !hasHeadlineSlot }"
    >
      <v-list-item
        dense
        class="pa-0 header"
      >
        <slot name="header-prepend" />
        <v-list-item-content>
          <div
            class="mb-1"
            v-if="hasHeadlineSlot"
          >
            <slot name="headline" />
          </div>
          <h2 class="text-lg">{{ title }}</h2>
          <div
            class="opacity-70 mt-1 pr-3"
            v-if="subtitle"
          >
            {{ subtitle }}
          </div>
        </v-list-item-content>
        <slot name="header-append" />
      </v-list-item>
      <template v-slot:actions>
        <v-avatar
          size="32px"
          class="accordion-icon"
        >
          <v-icon color="black">mdi-chevron-down</v-icon>
        </v-avatar>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-8">
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'AccordionMainPanel',
  props: {
    title: {
      default: '',
    },
    subtitle: {
      default: '',
    },
    disabled: {
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    hasHeadlineSlot() {
      return this.$slots.headline
    },
  },
}
</script>

<style lang="scss">
.accordion-main-panel {
  &.disabled {
    .header h2 {
      color: rgba(0, 0, 0, 0.5);
    }
    .will-step-icon {
      background-color: var(--grey-20);
    }
  }
}
.accordion-icon {
  border: 2px solid var(--grey);
  background-color: #ffffff;
}
.v-expansion-panel-header:not(.v-expansion-panel-header--active):hover
  .accordion-icon {
  background-color: #ffffff;
}
.v-expansion-panel-header.v-expansion-panel-header--active {
  background-color: var(--grey-0);
  .will-step-icon {
    background-color: var(--primary-10) !important;
  }
}

@media only screen and (max-width: 600px) {
  .v-expansion-panel-header {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.accordion-main-panel {
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--grey);
  }
}
</style>
